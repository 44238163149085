import Image from "next/image";
import { useCompanyDetail } from "../../../DataProvider";

import styles from "./index.module.scss";
import { MobileCard } from "../Card";
import React, { useState } from "react";

export const H5Header = () => {
  const { dictionary, companyPageData } = useCompanyDetail();
  const companyHeaderData = companyPageData?.company_basic || {};
  console.log("companyPageData", companyPageData);

  const tags = [
    {
      label: companyHeaderData.industry
    },
    {
      label: companyHeaderData.company_size
    },
    {
      label: companyHeaderData.company_size + "123124"
    }
  ].filter((v) => Boolean(v.label));

  return (
    <div className={styles.headerRoot}>
      <div
        style={{
          height: 40,
          alignSelf: "stretch",
          borderRadius: "12px",
          background: "#f8f9fa"
        }}
      />
      <div style={{ position: "absolute", left: 28, top: 22, zIndex: 1 }}>
        <Image
          width={64}
          height={64}
          className={styles.companyLogo}
          src={companyHeaderData.logo}
          alt='logo'
        />
      </div>
      <MobileCard
        style={{
          padding: "40px 16px 20px 16px",
          position: "relative",
          backgroundColor: "#fff",
          width: "100%"
        }}
      >
        <div className={styles.companyName}>
          <div>{companyHeaderData.name}</div>
        </div>
        <div className={styles.headerTagWrapper}>
          {tags.map((v, index) => {
            return (
              <React.Fragment key={v.label}>
                {index > 0 && <span className={styles.dot}>{` • `}</span>}
                <span>{v.label}</span>
              </React.Fragment>
            );
          })}
        </div>
      </MobileCard>
    </div>
  );
};
